import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import HeroBlog from '../components/hero_blog'
import Form from '../components/form'
import BlogContent from '../components/blog_content'

class BlogPostTemplate extends React.Component {

  render() {
    const pageData = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    let deck = '';
    let headerImage = false;
    if (pageData.deck) {
      deck = pageData.deck.file.url;
    }
    if (pageData.heroImage) {
      headerImage = pageData.heroImage.fluid;
    }

    return (
      <Layout location={this.props.location} title={pageData.title} >
        <HeroBlog title={pageData.title} image={headerImage} linkToIndex={true} />
        <BlogContent content={pageData.description} />
        <Form
          slug={pageData.slug}
          deck={deck}
          formCheckboxText={pageData.formCheckboxText}
          formCopyHeading={pageData.formCopyHeading}
          formThankYou={pageData.formThankYou}
        />
      </Layout>
    );
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      slug
      description {
        json
      }
      deck {
        file {
          url
        }
      }
      formCheckboxText
      formCopyHeading {
        formCopyHeading
      }
      formThankYou {
        formThankYou
      }
    }
  }
`
