import React from 'react';
import RichText from './richtext';
import { ArrowLeft } from './icons';

export default (props) => {
  let description;

  if (props.description) {
    if (typeof props.description === 'object' && 'json' in props.description) {
      description = <RichText {...props.description} />;
    } else {
      description = props.description;
    }
  }

  if (props.image) {
    return (
      <section className="blog-hero" style={{backgroundImage: `url(${props.image.src})`, backgroundRepeat: 'no-repeat', right: true}}>
        <div className="inner">
          {props.linkToIndex ? (
            <div>
              <a href="/" className="back">
                <ArrowLeft />
                Series Home
              </a>
              </div>
            ) : (
              ''
            )}
          <div className="content">
            <h1>{props.title}</h1>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="hero bg-light-grey">
      <div className="inner inner--small">
        <h1>{props.title}</h1>
      </div>
    </section>
  );
};
