import React from 'react';
import RichText from './richtext';

export default (props) => {
  let content;

  if (props.content) {
    if (typeof props.content === 'object' && 'json' in props.content) {
      content = <RichText {...props.content} />;
    } else {
      content = props.content;
    }
  }
  return (
      <section className="index-intro">
        <div className="inner inner--small">
          {content}
        </div>
      </section>
    );
  }
